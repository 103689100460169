import { Delete, Rectangle } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Container, Divider, Slide, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import api from "utils/Api";
import { useTheme } from "@mui/material/styles";
import UsersTable from "./UsersTable";
import UserInvitationTable from "./UserInvitationTable";
import { toast } from "react-toastify";
import Authentication from "auth/Authentication";

async function getAllUsers() {
  const users = await api.get("system/users", {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });

  return users.data.map((user) => {
    return { ...user, role: user.isSystemAdmin ? "admin" : "user" };
  });
}

async function modifyUser(user) {
  let url = `system/users/${user.uid}/remove-from-system`;
  if (user.role === "admin") url = `system/users/${user.uid}/set-as-system`;

  await api.put(url, undefined, {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });
  return { ...user };
}

async function deleteUser(user) {
  await api.delete(`system/users/${user.uid}`, {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });
  return user;
}

export default function SystemUserManagement() {
  const [users, setUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(true);

  const handleUserDelete = async (user) => {
    await deleteUser(user);
    setUsers(users.filter((u) => u.uid !== user.uid));
  };

  const handleUserUpdate = async (oldUser, newUser) => {
    let ignore = false;
    await modifyUser(newUser);
    if (ignore) return;
    setUsers((prevState) => {
      const data = [...prevState];
      let indexToModify = data.findIndex((x) => x.uid === oldUser.uid);
      if (indexToModify !== -1) data[indexToModify] = newUser;
      return data;
    });
    return () => (ignore = true);
  };

  useEffect(() => {
    let ignore = false;
    async function fetchData() {
      try {
        setLoadingUsers(true);
        let [users] = await Promise.all([getAllUsers()]);
        if (ignore) return;
        users = users.filter((user) => user.uid !== Authentication.user.uid);
        setUsers(users);
      } catch {
        toast.error("Failed to load users");
      } finally {
        if (ignore) return;
        setLoadingUsers(false);
      }
    }

    fetchData();
    return () => (ignore = true);
  }, []);

  return (
    <Container maxWidth={"md"}>
      <UsersTable
        users={users}
        loadingUsers={loadingUsers}
        updateUserAction={handleUserUpdate}
        deleteUserAction={handleUserDelete}
      />
      <br />
      <UserInvitationTable users={users} loadingUsers={loadingUsers} />
    </Container>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
