
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from '@mui/material/styles';
import Authentication from "auth/Authentication";
import React, { useState, useEffect, useRef } from "react";
import darkTheme from "themes/darkMode1";
import Index from "pages/Index";
import LoadingScreen from "utils/components/LoadingScreen";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import StyledToastContainer from "utils/components/StyledToastContainer";



function App() {

  const shouldDownloadFonts = useRef(true);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingFonts, setLoadingFonts] = useState(true);
  const navigate = useNavigate();

  //This is a hack to make sure the app doesn't render until the user is loaded
  useEffect(() => {
    const unsubscribe = Authentication.subscribeToUserChange((user) => {
      if (user == null) navigate("/sign-in", { replace: true });
      setLoadingUser(false);
    });
    return unsubscribe;
  }, []);

  //This is a hack to make sure the app doesn't render until the fonts are loaded
  useEffect(() => {
    (async () => {
      if (!shouldDownloadFonts.current) return;
      shouldDownloadFonts.current = false;
      const WebFont = await import("webfontloader");
      WebFont.load({
        google: { families: ["Montserrat:300,400,600,700,800", "Barlow Condensed:300,400,600,700,800"] },
        fontactive: () => setLoadingFonts(false),
      });
    })();
  }, []);



  return (
    <ThemeProvider theme={darkTheme} >
      <CssBaseline />
      <LoadingScreen forceFullScreen={true} loading={loadingFonts || loadingUser}>
        <Index />
        <StyledToastContainer />
      </LoadingScreen>
    </ThemeProvider>
  );
}

function later(delay) {
  return new Promise(function (resolve) {
    setTimeout(resolve, delay);
  });
}


export default App;
