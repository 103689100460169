import { StartGoogleSignIn, CompleteGoogleSignIn } from "./SignInWithGoogle";
import React, { useEffect } from "react";

export default function GoogleSignButton({
  onSignIn,
  onSignInError,
  renderButton = (onClick) => {
    return <button onClick={onClick}>Sign In</button>;
  },
}) {
  useEffect(() => {
    async function completeGoogleSignIn() {
      try {
        let result = await CompleteGoogleSignIn();
        if (result != null && onSignIn != null) {
          onSignIn(result);
        }
      } catch (e) {
        if (onSignInError != null) onSignInError(e);
      }
    }
    completeGoogleSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSignInClick = async () => {
    StartGoogleSignIn();
  };

  return renderButton(handleSignInClick);
}
