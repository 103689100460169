import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Slide,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import api from "utils/Api";
import { useTheme } from "@mui/material/styles";
import { StyledLoadingButton } from "utils/components/StyledLoadingButton";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UsersTable({
  users,
  loadingUsers,
  addUserAction,
  deleteUserAction,
  updateUserAction,
  roleKey = "role",
  roles = [
    { display: "Administrador", id: "admin" },
    { display: "Usuario", id: "user" },
  ],
}) {
  const [loadingAddUser, setLoadingAddUser] = useState(new Set());
  const [deleteConfimationDialog, setDeleteConfimationDialog] = useState({
    open: false,
    user: {},
  });
  const [[loadingDeleteUser], setLoadingDeleteUser] = useState([new Set()]);
  const [[loadingUpdateRole], setLoadingUpdateRole] = useState([new Set()]);

  const handleUpdate = async (oldUser, newUser) => {
    setLoadingUpdateRole([loadingUpdateRole.add(oldUser.uid)]);
    try {
      const res = await updateUserAction(oldUser, newUser);
      toast.success(`Usuario ${oldUser.displayName} actualizado`);
    } catch (err) {
      toast.error("Error al actualizar usuario");
    } finally {
      setLoadingUpdateRole((prev) => {
        loadingUpdateRole.delete(oldUser.uid);
        return [loadingUpdateRole];
      });
    }
  };

  const handleDelete = async (user) => {
    if (user.uid == null) return;
    try {
      setLoadingDeleteUser([loadingDeleteUser.add(user.uid)]);
      await deleteUserAction(user);
      toast.success(`Usuario ${user.displayName} eliminado`);
    } catch (err) {
      toast.error("Error al eliminar usuario");
    } finally {
      setLoadingDeleteUser((prev) => {
        loadingDeleteUser.delete(user.uid);
        return [loadingDeleteUser];
      });
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          marginTop: 20,
        }}
      >
        <Typography variant="subtitle2" gutterBottom component="div">
          Usuarios
        </Typography>
      </div>
      <Divider style={{ marginBottom: "20px" }} />

      {loadingUsers ? (
        <Skeleton variant="rounded" height={300} />
      ) : (
        <List
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
            //   outline: "1px solid divider.dark",
          }}
        >
          {users.length == 0 && (
            <ListItem>
              <Typography>No hay usuarios que mostrar</Typography>
            </ListItem>
          )}

          {users.map((user, index) => (
            <React.Fragment key={index}>
              <ListItem
                secondaryAction={
                  <React.Fragment>
                    <Select
                      disabled={
                        loadingUpdateRole.has(user.uid) ||
                        loadingDeleteUser.has(user.uid)
                      }
                      input={<OutlinedInput sx={{ paddingTop: 0 }} />}
                      value={user[roleKey]}
                      onChange={async (e) => {
                        const newUser = { ...user, role: e.target.value };
                        await handleUpdate(user, newUser);
                      }}
                      size="small"
                      sx={{ marginRight: 1 }}
                    >
                      {roles.map((role, index) => {
                        return (
                          <MenuItem key={role.id} value={role.id}>
                            {role.display}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <StyledLoadingButton
                      color="error"
                      variant="outlined"
                      size="medium"
                      onClick={() => {
                        setDeleteConfimationDialog({
                          open: true,
                          user,
                        });
                      }}
                      loading={
                        loadingDeleteUser.has(user.uid) ||
                        loadingUpdateRole.has(user.uid)
                      }
                    >
                      <Delete />
                    </StyledLoadingButton>
                  </React.Fragment>
                }
              >
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    referrerPolicy="no-referrer"
                    alt={user.displayName}
                    src={`${user.photoURL}=w300` /*+"=l96-c"*/}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={user.displayName}
                  secondary={user.email}
                />
              </ListItem>

              {index < users.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
      <Dialog
        open={deleteConfimationDialog.open}
        onClose={() => {
          setDeleteConfimationDialog({ open: false, user: {} });
        }}
        aria-labelledby="alert-dialog-title"
        keepMounted
        fullWidth
        TransitionComponent={Transition}
      >
        <DialogTitle>Eliminar usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estas seguro de eliminar el usuario{" "}
            {deleteConfimationDialog.user.displayName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            onClick={() => {
              setDeleteConfimationDialog({ open: false, uid: {} });
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={async () => {
              setDeleteConfimationDialog({ open: false, uid: {} });
              await handleDelete(deleteConfimationDialog.user);
            }}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
