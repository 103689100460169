import Authentication from "auth/Authentication";
import { GoogleAuthProvider, signInWithRedirect, getRedirectResult } from "firebase/auth";

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });


function StartGoogleSignIn() {
    signInWithRedirect(Authentication.auth, provider);
}

async function CompleteGoogleSignIn() {
    let result = await getRedirectResult(Authentication.auth);
    if (result == null || result.user == null) return null;
    return result.user.uid;
}

export { StartGoogleSignIn, CompleteGoogleSignIn };