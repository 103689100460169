import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import Authentication from "auth/Authentication";
import GoogleSignButton from "auth/components/GoogleSignInButton";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleUserChange = (user) => {
    if (user != null) {
      console.log("user is not null: ", user);
      navigate("/dashboard", { replace: true });
    } else {
      console.log("user is null");
      setLoading(false);
    }
  };

  useEffect(() => {
    let unsubscribe = Authentication.subscribeToUserChange(handleUserChange);
    return unsubscribe;
  }, []);

  if (loading) return <React.Fragment />;
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        overflow: "hidden",
        position: "fixed",
      }}
    >
      <Container
        maxWidth="md"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          flexDirection: "column",
        }}
      >
        <Typography variant="h2" align="center">
          RADIOACTIVE GAMES
        </Typography>
        <br />
        <GoogleSignButton
          renderButton={(onClick) => (
            <Button variant="contained" size="large" onClick={onClick}>
              Entrar
            </Button>
          )}
        />
      </Container>
    </div>
  );
}
