import {
  CheckCircleRounded,
  CheckTwoTone,
  Error,
  ErrorOutlineOutlined,
  ErrorRounded,
  ErrorTwoTone,
  InfoRounded,
  InfoTwoTone,
  ReportOutlined,
  ReportTwoTone,
  WarningRounded,
  WarningTwoTone,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

export default function StyledToastContainer() {
  const theme = useTheme();

  const StyledContainer = styled(ToastContainer)`
    // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
    &&&.Toastify__toast-container {
    }
    .Toastify__toast {
      border-radius: ${theme.shape.borderRadius * 2}px;
      padding-right: ${theme.spacing(1) * 10}px;
    }
    .Toastify__toast-icon {
      width: 50px;
    }
    .Toastify__progress-bar {
    }
    --toastify-font-family: ${theme.typography.body2};

    --toastify-text-color-light: ${theme.palette.text.primary};
    --toastify-text-color-dark: ${theme.palette.text.primary};

    --toastify-color-light: ${theme.palette.background.paper};
    --toastify-color-dark: ${theme.palette.background.paper};

    --toastify-color-info: ${theme.palette.info.main};
    --toastify-color-success: ${theme.palette.success.main};
    --toastify-color-warning: ${theme.palette.warning.main};
    --toastify-color-error: ${theme.palette.error.main};

    --toastify-text-color-info: ${theme.palette.info.contrastText};
    --toastify-text-color-success: ${theme.palette.success.contrastText};
    --toastify-text-color-warning: ${theme.palette.warning.contrastText};
    --toastify-text-color-error: ${theme.palette.error.contrastText};
  `;

  return (
    <StyledContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeButton={false}
      className="toastify-container"
      theme="light"
      icon={({ theme, type }) => {
        switch (type) {
          case "info":
            return <InfoTwoTone fontSize="large" color={theme} />;
          case "success":
            return <CheckTwoTone fontSize="large" color={theme} />;
          case "warning":
            return <WarningTwoTone fontSize="large" color={theme} />;
          case "error":
            return <ReportTwoTone fontSize="large" color={theme} />;
        }
      }}
      style={{
        width: "500px",
      }}
    />
  );
}
