import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import api from "utils/Api";
import { useTheme } from "@mui/material/styles";
import { async } from "@firebase/util";
import { toast } from "react-toastify";
import { StyledLoadingButton } from "utils/components/StyledLoadingButton";
import Authentication from "auth/Authentication";

async function getAllValidEmails() {
  const emails = await api.get("system/emails", {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });

  return emails.data;
}

async function addValidEmail(email) {
  const data = {
    email: email,
  };
  const res = await api.post("system/emails", data, {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });
  return res.data;
}

async function deleteValidEmail(email) {
  const res = await api.delete(`system/emails?email=${email}`, {
    baseURL: process.env.REACT_APP_AUTH_URL,
  });
  return res.data;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserInvitationTable({ users, loadingUsers }) {
  const [emails, setEmails] = useState([]);
  const [loadingEmails, setLoadingEmails] = useState(true);
  const [showingDialog, setShowingDialog] = useState(false);
  const [dialogEmail, setDialogEmail] = useState("");
  const [addingEmail, setAddingEmail] = useState(false);
  const [[deletingEmails], setDeletingEmails] = useState([new Set()]);

  const getFilteredEmails = () => {
    return emails.filter(
      (x) =>
        users.find((y) => y.email === x) === undefined &&
        x != Authentication.user.email
    );
  };

  const fetchEmails = async () => {
    let [emails] = await Promise.all([getAllValidEmails()]);
    return emails;
  };

  const handleAddEmail = async () => {
    try {
      setAddingEmail(true);
      await addValidEmail(dialogEmail);
      setEmails((prev) => [...prev, dialogEmail]);
      toast(`Email ${dialogEmail} agregado correctamente`, { type: "success" });
    } catch (e) {
      toast("Error al crear invitación", { type: "error" });
    } finally {
      setAddingEmail(false);
    }
  };

  const handleDeleteEmail = async (email) => {
    try {
      setDeletingEmails([deletingEmails.add(email)]);
      await deleteValidEmail(email);
      setEmails((prev) => prev.filter((x) => x !== email));
    } catch (e) {
      toast(`Error al eliminar invitación de ${email}`, { type: "error" });
    } finally {
      setDeletingEmails((prev) => {
        deletingEmails.delete(email);
        return [deletingEmails];
      });
    }
  };

  useEffect(() => {
    setLoadingEmails(true);
    let ignore = false;
    async function fetchData() {
      const emails = await fetchEmails();
      if (!ignore) {
        setEmails(emails);
        setLoadingEmails(false);
      }
    }
    fetchData();
    return () => (ignore = true);
  }, []);

  const filteredEmails = getFilteredEmails();

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
        }}
      >
        <Typography variant="subtitle2" gutterBottom component="div">
          Invitaciones
        </Typography>
        <div style={{ flexGrow: 1 }} />
        <StyledLoadingButton
          variant="contained"
          color="primary"
          loading={loadingEmails || loadingUsers || addingEmail}
          onClick={() => {
            setShowingDialog(true);
          }}
        >
          Invitar
        </StyledLoadingButton>
      </div>
      <Divider style={{ marginTop: "10px", marginBottom: "20px" }} />

      {loadingEmails || loadingUsers ? (
        <Skeleton variant="rounded" height={100} />
      ) : (
        <List
          sx={{
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          {filteredEmails.length === 0 && (
            <ListItem>No hay invitaciones por mostrar</ListItem>
          )}
          {filteredEmails.map((email, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem
                  secondaryAction={
                    <React.Fragment>
                      <StyledLoadingButton
                        color="error"
                        variant="outlined"
                        loading={deletingEmails.has(email)}
                        key={index}
                        onClick={() => {
                          handleDeleteEmail(email);
                        }}
                      >
                        Eliminar
                      </StyledLoadingButton>
                    </React.Fragment>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      referrerPolicy="no-referrer"
                      alt={email}
                    />
                  </ListItemAvatar>
                  <ListItemText primary={email} />
                </ListItem>
                {index < filteredEmails.length - 1 && <Divider />}
              </React.Fragment>
            );
          })}
        </List>
      )}
      <Dialog
        open={showingDialog}
        onClose={() => setShowingDialog(false)}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
      >
        <DialogTitle>Invitar Usuario</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            value={dialogEmail}
            onChange={(e) => setDialogEmail(e.target.value)}
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            variant="filled"
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setShowingDialog(false)}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setShowingDialog(false);
              handleAddEmail();
            }}
          >
            Invitar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
