import { Outlet, useNavigate } from "react-router-dom";
import Authentication from "auth/Authentication";
import React, { useState, useEffect } from "react";
import LoadingScreen from "utils/components/LoadingScreen";

export default function ProtectedRoutes(props) {
  const { requiresSystem } = props;
  const navigate = useNavigate();
  const [authorization, setAuthorization] = useState(() => {
    return { loading: true, authorized: false };
  });

  function handleUserChange(user) {
    let authorized = user != null && (!requiresSystem || user.isSystemAdmin);
    if (!authorized) {
      navigate("/sign-in", { replace: true });
    } else {
      setAuthorization({ loading: false, authorized });
    }
  }

  useEffect(() => {
    let unsubscribe = Authentication.subscribeToUserChange(handleUserChange);
    return unsubscribe;
  }, []);

  return (
    <LoadingScreen loading={authorization.loading} forceFullScreen={true}>
      <Outlet />
    </LoadingScreen>
  );
}
