import { Button, Typography } from "@mui/material";
import Authentication from "auth/Authentication";
import ProtectedRoutes from "auth/components/ProtectedRoutes";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import SignIn from "./SignIn";
import SystemUserManagement from "./system/SystemUserManagement";

export default function Index() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" replace={true} />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route element={<ProtectedRoutes requiresSystem={true} />}>
        <Route path="/system" element={<SystemUserManagement />} />
      </Route>
      <Route element={<ProtectedRoutes />}>
        <Route
          path="/dashboard"
          element={
            <Button onClick={() => Authentication.signOut()}>
              Dasboard - Sign out
            </Button>
          }
        />
      </Route>
    </Routes>
  );
}
