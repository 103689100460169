import { useTheme } from "@mui/material/styles";
import BarLoader from "react-spinners/BarLoader";

export default function LoadingScreen({ loading, children, forceFullScreen }) {
  const theme = useTheme();
  let fullScreenStyle = {};
  if (forceFullScreen) {
    fullScreenStyle = {
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      overflow: "hidden",
      position: "fixed",
    };
  }

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...fullScreenStyle,
        }}
      >
        <BarLoader
          color={theme.palette.primary.main}
          loading={loading}
          size={150}
          aria-label="Loading Page"
        />
      </div>
    );

  return children;
}
