import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import BeatLoader from "react-spinners/BeatLoader";

export function StyledLoadingButton({
  loading,
  color,
  variant,
  onClick,
  ...props
}) {
  const theme = useTheme();
  return (
    <LoadingButton
      color={color}
      variant={variant}
      loading={loading}
      onClick={onClick}
      loadingPosition="center"
      loadingIndicator={
        <BeatLoader
          role="progressbar"
          color={theme.palette.action.disabled}
          size={6}
        />
      }
      {...props}
    />
  );
}
