import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "utils/Api";

const firebaseConfig = {
    apiKey: "AIzaSyAnIODUuHzoiRy6bSdOhUSh6PVr2JjjRP8",
    authDomain: "radioactive-tools.firebaseapp.com",
    projectId: "radioactive-tools",
    storageBucket: "radioactive-tools.appspot.com",
    messagingSenderId: "1098013418043",
    appId: "1:1098013418043:web:295a40953245151590721e"
};



class Authentication {
    #userChangeEvents = [];
    #isInitialized = false;

    constructor() {
        this.user = null;
        this.app = initializeApp(firebaseConfig);
        this.auth = getAuth(this.app);
        this.auth.onAuthStateChanged(this.#handleUserStateChange);

        //Add session token interceptor
        axios.interceptors.request.use(async (config) => {
            const token = await this.getSessionToken();
            config.headers["authorization"] = token ?? "";
            return config;
        });


    }

    static instance = this.instance || new Authentication();


    // This is the function that is called when the user state changes (login, logout, etc.)
    #handleUserStateChange = async (user) => {
        this.#isInitialized = true;
        this.#getUserData();
    }

    getSessionToken = async () => {
        if (this.auth.currentUser == null) return null;
        const token = await this.auth.currentUser.getIdToken();
        return token;
    }


    #getUserData = async () => {
        var token = await this.getSessionToken();
        if (token == null) {
            this.user = null;
            this.#userChangeEvents.forEach((handler) => handler(this.user));
            return;
        }
        let response = await axios.get("/login/", { baseURL: process.env.REACT_APP_AUTH_URL });
        this.user = response.data;
        if (this.user.uid == null) this.user = null;
        this.#userChangeEvents.forEach((handler) => handler(this.user));
    }


    subscribeToUserChange(handler) {
        if (this.#isInitialized) handler(this.user);//Call the handler with the current user
        this.#userChangeEvents.push(handler);
        return () => this.unsubscribeFromUserChange(handler);
    }

    unsubscribeFromUserChange(handler) {
        this.userChangeEvents = this.#userChangeEvents.filter((h) => h !== handler);
    }

    signOut = async () => {
        await this.auth.signOut();
    }

}


export default Authentication.instance;