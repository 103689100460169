const { createTheme } = require("@mui/material/styles");

export default createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: "#ffceec",
            main: "#f59cba",
            dark: "#c16c8a",
            contrastText: "#000",
        },
        secondary: {
            light: "#6dfffe",
            main: "#1ddecb",
            dark: "#00ab9a",
            contrastText: "#000",
        },
        text: {
            primary: "#e9e9e9",
            secondary: "rgba(255,255,255,0.54)",
            disabled: "rgba(255,255,255,0.38)",
            hint: "rgba(255,255,255,0.38)",
        },
        error: {
            light: "#f47961",
            main: "#e94f30",
            dark: "#d72f0f",
            contrastText: "#fff",
        },
        success: {
            light: "#35df90",
            main: "#1fb971",
            dark: "#178d56",
        },
        warning: {
            light: "#fac661",
            main: "#f0ae2d",
            dark: "#e39708",
        },
        // divider: "rgba(1,1,1,0.12)",
        // background: { default: "#25201d", paper: "#3b3831" },
        //  background: { default: "#222326", paper: "#1b1d21" },
        background: { default: "#121212", paper: "#1e1e1e" },
    },
    shape: { borderRadius: 6 },
    typography: {
        fontFamily: "Montserrat",
        h1: {
            fontFamily: "Barlow Condensed",
            fontWeight: 700,
            fontSize: "4.8rem",

        },
        h2: {
            fontFamily: "Barlow Condensed",
            fontWeight: 600,

        },
        h3: {
            fontFamily: "Barlow Condensed",
            fontWeight: 600,
        },
        h4: {
            fontFamily: "Barlow Condensed",
            fontWeight: 600,
        },
        h5: {
            fontFamily: "Barlow Condensed",
            fontWeight: 600,
        },
        h6: {
            fontFamily: "Barlow Condensed",
            fontWeight: 400,
        },
        button: {
            fontFamily: "Montserrat",
            fontWeight: 700,
            letterSpacing: "0.06071em",
        },
        subtitle1: {
            fontSize: "1.5rem",
            fontWeight: 600,
        },
        subtitle2: {
            fontSize: "1.25rem",
            fontWeight: 500,
        },
        body1: {
            fontSize: "1.05rem",
            fontWeight: 300,
        },
        body2: {
            fontSize: "0.95rem",
            fontWeight: 300,
        },
    },



});


